import { RouteUpdateArgs, ShouldUpdateScrollArgs } from 'gatsby'
import './src/styles/global.css'

export const onRouteUpdate = ({ location, prevLocation }: RouteUpdateArgs) => {
  // トップページの場合、bodyスタイルを上書き
  if (location.pathname === '/') {
    document.body.style.setProperty('background-color', '#fff', 'important')
  }
}

/**
 * ページ位置の保持
 */
interface CustomLocation extends Location {
  key: string // 必要なプロパティを追加
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}: ShouldUpdateScrollArgs & { routerProps: { location: CustomLocation } }) => {
  const postion = getSavedScrollPosition(location, location.key)

  // 少し遅らせてスクロール
  setTimeout(() => {
    window.scrollTo(...(postion || [0, 0]))
  }, 100)

  return false
}
